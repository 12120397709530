<template>
  <base-section
    id="Supporters"
    class="grey lighten-4"
    space="36"
  >
    <v-container>
      <base-section-heading title="Video Interview" />
      <!-- <v-slide-group>
        <template v-for="(support,i) in items">
          <v-slide-item
            :key="i"
            class="align-self-center"
          >
            <base-img
              :src="require(`@/assets/logo/${support.logo}`)"
              color="grey"
              contain
              height="70"
              width="128"
            />
          </v-slide-item>

          <v-responsive
            v-if="i < (itemlength - 1)"
            :key="`divider-${i}`"
            class="text-center"
            height="110"
            width="48"
          >
            <v-divider vertical />
          </v-responsive>
        </template>
      </v-slide-group> -->

        <base-body class="text-justify">
          Watch as one of our founders introduces Aptamimetics in an exclusive interview. Jan talks about the broad applications of our products and our journey how to get there. He also provides brief insights on the motivation and goals of the Aptamimetics team. Currently only available in German, but feel free to
          <router-link
            tag="span"
            to="contact-us"
            class="mx-1 ml-1 link primary--text"
          >
            contact
          </router-link>us for a live conversation in English!
        </base-body>

      <v-row justify="center">
        <v-col
          cols="12"
          md="6"
        >
          <div class="red containerYT">
            <iframe
              :width="width"
              :height="height"
              :src="video"
              title="Aptamimetics in 120s (German)"
              frameborder="0"
              class="videoYT"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionVideoInterview',
    data: () => ({
      video: 'https://www.youtube.com/embed/J74M2x8Viiw?si=ZeZ0RF_DD6QMnlyH', // Replace with your YouTube video ID
      width: 560, // Set your preferred width
      height: 315, // Set your preferred height
    }),

  }
</script>
